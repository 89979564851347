var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-row",
    { staticClass: "form-group" },
    [
      _c("b-col", { staticClass: "col-sm-3 hidden-xs" }, [_c("label")]),
      _c("b-col", { staticClass: "col-sm-4" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/cards/visa.png"),
            width: "33",
            height: "20",
            title: "VISA",
          },
        }),
        _c("img", {
          attrs: {
            src: require("../../assets/images/cards/mastercard.png"),
            width: "33",
            height: "20",
            title: "MASTERCARD",
          },
        }),
        _c("img", {
          attrs: {
            src: require("../../assets/images/cards/amex.png"),
            width: "33",
            height: "20",
            title: "AMEX",
          },
        }),
        _c("img", {
          attrs: {
            src: require("../../assets/images/cards/elo.png"),
            width: "33",
            height: "20",
            title: "ELO",
          },
        }),
        _c("img", {
          attrs: {
            src: require("../../assets/images/cards/discover.png"),
            width: "33",
            height: "20",
            title: "DISCOVER",
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }