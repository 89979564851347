var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c("loading", {
            attrs: {
              active: _vm.isLoading,
              "can-cancel": false,
              "is-full-page": _vm.fullPage,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          }),
          _c(
            "b-col",
            { staticClass: "col-sm-6" },
            [
              _c(
                "b-form-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.readonly,
                      expression: "!readonly",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _c("label", [_vm._v("* Tipo: ")]),
                  ]),
                  _c("b-col", { staticClass: "col-sm-9" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.type,
                            expression: "user.type",
                          },
                        ],
                        attrs: { type: "radio", value: "Pessoa Física" },
                        domProps: {
                          checked: _vm._q(_vm.user.type, "Pessoa Física"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.user, "type", "Pessoa Física")
                          },
                        },
                      }),
                      _vm._v(" Pessoa Física "),
                    ]),
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.type,
                            expression: "user.type",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "cnpj-radio-button",
                          value: "Pessoa Jurídica",
                        },
                        domProps: {
                          checked: _vm._q(_vm.user.type, "Pessoa Jurídica"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.user, "type", "Pessoa Jurídica")
                          },
                        },
                      }),
                      _vm._v(" Pessoa Jurídica "),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-row",
                {
                  staticClass: "form-group",
                  class: { "form-group--error": _vm.$v.user.$error },
                },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _vm.user.type === "Pessoa Física"
                      ? _c("label", [_vm._v("*CPF:")])
                      : _c("label", [_vm._v("*CNPJ: ")]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "col-sm-6" },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: _vm.dynamicMask,
                            expression: "dynamicMask",
                          },
                        ],
                        class: _vm.status(_vm.$v.user.personalId),
                        attrs: { readonly: _vm.readonly },
                        model: {
                          value: _vm.$v.user.personalId.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.user.personalId, "$model", $$v)
                          },
                          expression: "$v.user.personalId.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-row",
                { staticClass: "form-group" },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _c("label", [_vm._v("*Nome: ")]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "col-sm-9" },
                    [
                      _c("b-form-input", {
                        class: _vm.status(_vm.$v.user.name),
                        attrs: { readonly: _vm.readonly },
                        on: { keypress: _vm.IsText },
                        model: {
                          value: _vm.$v.user.name.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.user.name, "$model", $$v)
                          },
                          expression: "$v.user.name.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-row",
                { staticClass: "form-group" },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _c("label", [_vm._v("*Telefone: ")]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "col-sm-6" },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "(##) #########",
                            expression: "'(##) #########'",
                          },
                        ],
                        class: _vm.status(_vm.$v.user.phone),
                        attrs: { readonly: _vm.readonly },
                        model: {
                          value: _vm.$v.user.phone.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.user.phone, "$model", $$v)
                          },
                          expression: "$v.user.phone.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-row",
                { staticClass: "form-group" },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _c("label", [_vm._v("*E-mail: ")]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "col-sm-9" },
                    [
                      _c("b-form-input", {
                        class: _vm.status(_vm.$v.user.email),
                        attrs: { readonly: _vm.readonly },
                        model: {
                          value: _vm.$v.user.email.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.user.email, "$model", $$v)
                          },
                          expression: "$v.user.email.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-row",
                { staticClass: "form-group" },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _c("label", [_vm._v("*CEP:")]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "col-sm-4" },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "#####-###",
                            expression: "'#####-###'",
                          },
                        ],
                        class: _vm.status(_vm.$v.user.zipCode),
                        attrs: { readonly: _vm.readonly },
                        model: {
                          value: _vm.$v.user.zipCode.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.user.zipCode, "$model", $$v)
                          },
                          expression: "$v.user.zipCode.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "col-sm-6" },
            [
              _c(
                "b-form-row",
                {
                  staticClass: "form-group",
                  class: { "form-group--error": _vm.$v.user.$error },
                },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _c("label", [_vm._v("*Logradouro:")]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "col-sm-9" },
                    [
                      _c("b-form-input", {
                        class: _vm.status(_vm.$v.user.address),
                        attrs: { readonly: _vm.readonly },
                        model: {
                          value: _vm.$v.user.address.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.user.address, "$model", $$v)
                          },
                          expression: "$v.user.address.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-row",
                { staticClass: "form-group" },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _c("label", [_vm._v("*Número: ")]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "col-sm-3" },
                    [
                      _c("b-form-input", {
                        class: _vm.status(_vm.$v.user.number),
                        attrs: { readonly: _vm.readonly },
                        on: { keypress: _vm.IsNumber },
                        model: {
                          value: _vm.$v.user.number.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.user.number, "$model", $$v)
                          },
                          expression: "$v.user.number.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-row",
                { staticClass: "form-group" },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _c("label", [_vm._v("Complemento:")]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "col-sm-9" },
                    [
                      _c("b-form-input", {
                        attrs: { readonly: _vm.readonly },
                        model: {
                          value: _vm.user.complement,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "complement", $$v)
                          },
                          expression: "user.complement",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-row",
                { staticClass: "form-group" },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _c("label", [_vm._v("*Bairro:")]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "col-sm-7" },
                    [
                      _c("b-form-input", {
                        class: _vm.status(_vm.$v.user.district),
                        attrs: { readonly: _vm.readonly },
                        model: {
                          value: _vm.user.district,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "district", $$v)
                          },
                          expression: "user.district",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-row",
                { staticClass: "form-group" },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _c("label", [_vm._v("*Estado: ")]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "col-sm-7" },
                    [
                      _c("b-select", {
                        staticClass: "selectpicker form-control",
                        class: _vm.status(_vm.$v.user.state),
                        staticStyle: { "min-width": "285px" },
                        attrs: { disabled: _vm.readonly, options: _vm.states },
                        model: {
                          value: _vm.user.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "state", $$v)
                          },
                          expression: "user.state",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form-row",
                { staticClass: "form-group" },
                [
                  _c("b-col", { staticClass: "col-sm-3 control-label" }, [
                    _c("label", [_vm._v("*Cidade: ")]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "col-sm-7" },
                    [
                      _c("b-select", {
                        staticClass: "selectpicker form-control",
                        class: _vm.status(_vm.$v.user.city),
                        staticStyle: { "min-width": "285px" },
                        attrs: { disabled: _vm.readonly, options: _vm.cities },
                        model: {
                          value: _vm.user.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "city", $$v)
                          },
                          expression: "user.city",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.readonly,
              expression: "!readonly",
            },
          ],
          staticClass: "col-sm-12 bottom-bar",
        },
        [
          _c("ul", { staticClass: "list-inline float-right" }, [
            _c(
              "li",
              { staticClass: "list-inline-item" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "button-back-style",
                    on: {
                      click: function ($event) {
                        return _vm.backToInitialTab()
                      },
                    },
                  },
                  [_vm._v("Voltar")]
                ),
              ],
              1
            ),
            _c(
              "li",
              { staticClass: "list-inline-item" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "button-style",
                    on: {
                      click: function ($event) {
                        return _vm.handleUserFormData()
                      },
                    },
                  },
                  [_vm._v("Avançar")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }